'use strict';

/* eslint-disable */
var dataLayer = window.dataLayer;
var Cookies = require('js-cookie');
var modalCookieName = 'hide_opIn_modal';
var modalCookieDomain = '.netgear.com';

/**
 * Renders a promotional modal
 */
function showPromoModal() {
    var $promoModal = $('#promoModal');
    $promoModal.modal();

    $promoModal.on('hidden.bs.modal', function () {
        var modalCookie = Cookies.get(modalCookieName);
        if (modalCookie !== '2') {
            $('.sign-up-callout').fadeIn();
            Cookies.set(modalCookieName, 1, { expires: 1, domain: modalCookieDomain, secure: true });
        }
        dataLayer.push({
            event: 'e_emailSubscribeClose',
            signupLocation: 'welcome email'
        });
    });

    $promoModal.on('submit', 'form', function () {
        if (dataLayer) {
            dataLayer.push({
                event: 'e_emailSubscribe',
                signupResult: 'success',
                signupLocation: 'welcome email'
            });
        }
        Cookies.set(modalCookieName, 2, { expires: 1, domain: modalCookieDomain, secure: true });
        $('.opt-in-form').removeClass('active');
        $('.email-opt-in-success').addClass('active');
    });

}

module.exports = function () {
    var $page = $('.page');
    var isCartPage = $page.data('action') === 'Cart-Show' && $page.find('.cartitems').length;
    var isCheckout = $page.data('action').indexOf('Checkout') !== -1;
    var isproavPage = $page.find('.proavMarketingPage').length > 0;
    var modalCookie = Cookies.get(modalCookieName);
    if (!modalCookie && !isCartPage && !isCheckout && !isproavPage) {
        showPromoModal();
    } else if (modalCookie === '1' && !isCartPage && !isCheckout && !isproavPage) {
        $('.sign-up-callout').fadeIn();
    }

    $('.sign-up-button').on('click', function () {
        $(this).parent().fadeOut();
        showPromoModal();
    });

    $('.sign-up-close-button').on('click', function () {
        $(this).parent().fadeOut();
    });
};
