var dataLayer = window.dataLayer;
/**
 * Renders a promotional page
 */
function showPromoPage() {
    var $promoPage = $('#promoPage');

    $promoPage.on('submit', 'form', function () {
        if (dataLayer && $('.error.invalid-email').hasClass('d-none') && $('.error.txt-email').hasClass('d-none')) {
            dataLayer.push({
                event: 'e_emailSubscribe',
                signupResult: 'success',
                signupLocation: 'welcome email'
            });
        }
    });
}

module.exports = function () {
    showPromoPage();
};
