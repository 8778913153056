var slickConfigs = require('../config/slickConfigs');
var $carousel = $('.cat-marketing-tab-caro');

// re-initialize slick when the show instance method is called
$('.feat-prod').on('show.bs.collapse', function () {
    $('.cat-marketing-accord-caro').slick('unslick');
    $('.cat-marketing-accord-caro').slick(slickConfigs.featureProdCarouselsMobile);
});

// toggle between plus and minus icon on accordion
$('.collapse').on('show.bs.collapse', function () {
    var collapsedIcon = $(this).is('.collapse-chevron') ? 'fa-chevron-down' : 'fa-plus';
    var expandedIcon = $(this).is('.collapse-chevron') ? 'fa-chevron-up' : 'fa-minus';
    var cardHeader = $(this).prev('.card-header');
    var logo = cardHeader.find('.logo-button');
    cardHeader.find('.fa').removeClass(collapsedIcon)
    .addClass(expandedIcon);
    cardHeader.addClass('selected');
    logo.fadeOut();
}).on('hide.bs.collapse', function () {
    var collapsedIcon = $(this).is('.collapse-chevron') ? 'fa-chevron-down' : 'fa-plus';
    var expandedIcon = $(this).is('.collapse-chevron') ? 'fa-chevron-up' : 'fa-minus';
    var cardHeader = $(this).prev('.card-header');
    var logo = cardHeader.find('.logo-button');
    cardHeader.find('.fa').removeClass(expandedIcon)
    .addClass(collapsedIcon);
    cardHeader.removeClass('selected');
    logo.fadeIn();
});

$('.logo-insert').each(function () {
    var logo = $(this).closest('.card').find('.logo-button');
    var cloned = logo.clone();
    $(this).append(cloned);
});

/**
 * For non-Slick-carousel Category Marketing templates, hide all tiles and show only the ones that have the matching data attribute
 * @function filterByValue
 * @param {string} filterValue - The data value used to filter tiles.
 * @param {string} t - The target value of the click event, used to differentiate between carousels.
 */
function filterByValue(filterValue, t) {
    var tiles = $(t).closest('[id^=slot-cat-marketing]').find('.cat-marketing-tab-no-caro .cat-marketing-tile');
    var filteredTiles = $(t).closest('[id^=slot-cat-marketing]').find('.cat-marketing-tile[data-cat="' + filterValue + '"]');

    tiles.hide();
    filteredTiles.show();
}

module.exports = {
    // filtering the product according to their category
    attributeFilter: function () {
        $('.js-nav').on('click', function (e) {
            var t = e.target;
            var filterValue = t.dataset.filter; // taget filter value
            var categoryItems = $('#tab-cat-marketing .nav-item');
            var parentLi = $(t).parent();
            categoryItems.removeClass('selected');
            parentLi.addClass('selected');

            if (typeof filterValue !== 'undefined') {
                $carousel.slick('slickUnfilter'); // unslick to retrived all products
                $carousel.slick('slickFilter', // apply filter
                    function () { // return condition for filtering
                        return $(this).data('cat') === filterValue;
                    });
            }
        });
    },
    attributeFilterNoCarouselInit: function () {
        var targets = $('[id^=slot-cat-marketing] .tabs');
        $(targets).each(function () {
            var filterValue = $(this).find('.nav-item.selected .js-nav-no-caro').data('filter');
            filterByValue(filterValue, this);
        });
    },
    attributeFilterNoCarousel: function () {
        $('.js-nav-no-caro').on('click', function (e) {
            var t = e.target;
            var filterValue = t.dataset.filter; // taget filter value
            var categoryItems = $(t).closest('[id^=slot-cat-marketing]').find('#tab-cat-marketing-no-caro .nav-item');
            var parentLi = $(t).parent();
            categoryItems.removeClass('selected');
            parentLi.addClass('selected');
            filterByValue(filterValue, t);
        });
    },
    initProductDealsTimer: function () {
        var second = 1000;
        var minute = second * 60;
        var hour = minute * 60;
        var day = hour * 24;
        var startDateSource = $('.callout-msg-deals').data('startdate');
        var endDateSource = $('.callout-msg-deals').data('enddate');
        var newDate = new Date();

        var startDate = new Date(startDateSource);
        var endDate = new Date(endDateSource);

        /**
         * Prepends '0' only if the number is a single digit
         * @function doubleDigit
         * @param {number} number - the current number in the timer
         * @returns {string} - the number with prepended '0' if needed
         */
        function doubleDigit(number) {
            return number.toString().length > 1 ? number : ('0' + number).slice(-2);
        }

        // if the start date has been passed; render countdown
        if (startDate < newDate && endDate > newDate) {
            var countDown = endDate.getTime();
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDown - now;

                $('#count-down').removeClass('hidden');
                document.getElementById('days').innerText = doubleDigit(Math.floor(distance / (day)));
                document.getElementById('hours').innerText = doubleDigit(Math.floor((distance % day) / hour));
                document.getElementById('minutes').innerText = doubleDigit(Math.floor((distance % hour) / minute));
                document.getElementById('seconds').innerText = doubleDigit(Math.floor((distance % minute) / second));

                if (distance < 0) {
                    clearInterval(x);
                }
            }, second);
        } else {
            $('#count-down').hide();
        }
    }
};
