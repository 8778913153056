/* eslint-disable */
$(document).on('click', '.dwr-live-chat', function(){
   $('.salesforceliveagent img').each(function(index, el){
     var $this = $(this);

     if($this.is(":visible")) {
       $this.click();
     }
   });
 });

$(window).on('load', function() {
    setTimeout(function() {
        if ($('.embeddedServiceHelpButton').length === 0) {
            $('#nocookie-chat').css('display', 'block');
        }
    }, 3000);
});

 // this will turn form into ajax form submit
 //new GenericAjaxForm( { el: $('.generic-ajax-form') } );
 $('.generic-ajax-form').each(function(index, el){
   new GenericAjaxForm( { el: $(this) } );
 });


 // this will check if salesforce chat agent is online or offline
 // if life chat agent is online live chat link will show.
 (function(){

   var liveagentOnline = $('#liveagent_button_online_xxxx000CaZr');

   if(liveagentOnline.length > 0) {
     var hideEls = function(){
       $('.dwr-live-chat').hide();
       $('.or-chat').hide();
     };

     var showEls = function(){
       $('.dwr-live-chat').show();
       $('.or-chat').show();
     };

     // page onload
     if(!liveagentOnline.is(':visible')) {
       hideEls();
     }

     // on ajax complete
     $( document ).ajaxComplete(function( event, request, settings ) {
       if(!$('#liveagent_button_online_xxxxx0CaZr').is(':visible')) {
         hideEls();
       }
     });

     var observer = new MutationObserver(function(mutations) {
       mutations.forEach(function(mutationRecord) {
           //console.log (mutationRecord.type);
           if($('#liveagent_button_online_xxxxx000CaZr').is(':visible')) {
             showEls();
           } else {
             console.log('liveagent offline!');
             hideEls();
           }
       });
     });

     observer.observe(liveagentOnline[0], { attributes : true, attributeFilter : ['style'] });
   }

 })();