'use strict';
var productbase = require('../product/base');
var keyboardAccessibility = require('base/components/keyboardAccessibility');
module.exports = function () {
    var showMinicart = function () {
        $('body').trigger('beforeMinicartOpen');
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            $('.minicart-link').removeClass('disabled');
            $('.minicart-link').attr('aria-expanded', 'true');
            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                $.spinner().stop();
            });
        }
    };

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            var $minicartLink = $('.minicart .minicart-link');
            var template = $minicartLink.data('titleTemplate');
            var adaTitle = template.replace('#', count.quantityTotal);
            $minicartLink.prop('title', adaTitle, 'ariaLabel', adaTitle);
            $('.minicart .minicart-quantity').text(count.quantityTotal);
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        showMinicart();
    });

    keyboardAccessibility('.minicart, .minicart a',
        {
            27: function () { // escape
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
                $('.minicart-link').attr('aria-expanded', 'false');
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
            $('.minicart-link').attr('aria-expanded', 'false');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').empty();
        $('.minicart .popover').removeClass('show');
        $('.minicart-link').attr('aria-expanded', 'false');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    // event listener for add to cart button on product tile for category landing page
    $('body').on('click', '.cat-marketing button.add-tile-to-cart, .proav-products button.add-tile-to-cart', function () {
        var addToCartUrl = $(this).parent().find('.add-to-cart-url').val();
        var pid = $(this).attr('data-pid');
        var displayAddToCartModal = $(this).attr('data-display-modal');
        // eslint-disable-next-line no-multi-spaces
        var isMobile =  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        var actionURL = $(this).attr('data-url');
        $('body').trigger('product:beforeAddToCart', this);

        var form = {
            pid: $(this).attr('data-pid'),
            quantity: '1',
            options: []
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (displayAddToCartModal === 'true' && !isMobile) {
                        productbase.addToCartOnCategoryLandingPage(data, pid, actionURL);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                    } else {
                        if (data.defaultUpsells.length > 0) {
                            $.ajax({
                                url: addToCartUrl,
                                method: 'POST',
                                data: { pid: data.defaultUpsells[0],
                                    hardwarePLI: data.pliUUID,
                                    quantity: '1'
                                },
                                success: function (response) {
                                    productbase.handlePostCartAdd(response);
                                },
                                error: function () {
                                    $.spinner().stop();
                                }
                            });
                        }
                        productbase.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        showMinicart();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    // event listener for add to cart button on product tile for category landing page
    $('body').on('click', 'button.add-set-tile-to-cart', function () {
        var addToCartUrl = $(this).parent().find('.add-to-cart-url').val();
        // alert('hi');
        $('body').trigger('product:beforeAddToCart', this);
        var productSetIds = JSON.parse($(this).attr('data-productSetIds'));
        var setPids = [];
        for (var i = 0; i < productSetIds.length; i++) {
            setPids.push({
                pid: productSetIds[i],
                qty: '1'
            });
        }
        var pidsObj = JSON.stringify(setPids);

        var form = {
            pid: $(this).attr('data-pid'),
            pidsObj: pidsObj,
            quantity: '1',
            options: []
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (data.defaultUpsells.length > 0) {
                        $.ajax({
                            url: addToCartUrl,
                            method: 'POST',
                            data: { pid: data.defaultUpsells[0],
                                hardwarePLI: data.pliUUID,
                                quantity: '1'
                            },
                            success: function (response) {
                                productbase.handlePostCartAdd(response);
                            },
                            error: function () {
                                $.spinner().stop();
                            }
                        });
                    }
                    productbase.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    var isCartPage = window.location.href.indexOf('cart');
                    if (isCartPage > 0) {
                        window.location.reload();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $('body').on('click', '.service-close', function () {
        setTimeout(function () {
            showMinicart();
        }, 1000);
    });

    $('body').on('click', '.minicart-link', function () {
        $(this).addClass('disabled');
    });
};
