'use strict';

// Suppress rating stars if empty in product tile.
$(window).on('load', function () {
    var ratingsArray = $('.bv-ratings');
    for (var i = 0; i < ratingsArray.length; i++) {
        if (ratingsArray[i].innerText.slice(0, 3) === '0.0') {
            $(ratingsArray[i]).hide();
        }
    }
});
