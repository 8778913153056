'use strict';
/* global pageContext */

var dataLayer = window.dataLayer;

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 */
function productClick(productObject) {
    var obj = {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: {
                    list: 'SearchResults'
                },
                products: []
            }
        }
    };

    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 */
function addToCart(productObject, quantity) {
    var prevAddStep;
    dataLayer.forEach(function name(ele) {
        if (ele.event === 'e_addtocart') {
            prevAddStep = ele;
            dataLayer.splice(dataLayer.indexOf(prevAddStep),1);
        }
    });
    var cartData = $.parseJSON($('#cartdata').attr('data-gtmdata'));
    var quantityObj = {
        quantity: quantity
    };

    dataLayer.push({ ecommerce: null });
    var obj = {
        event: 'e_addtocart',
        'preorder': productObject.preorder,
        'ga4_event_name':'add_to_cart',

        ecommerce: {
            currency: cartData.currency,
            value: productObject.price,
            promotion_id: '',
            promotion_name: '',
            item_list_id: '',
            item_list_name: '',
            creative_name: '',
            creative_slot: '',
            items: []
        }
    };

    obj.ecommerce.items.push($.extend(productObject, quantityObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}

// eslint-disable-next-line valid-jsdoc
/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 * @param {string} currentList
 * @param {string} currentPosition
 */
function addTileToCart(productObject, quantity, currentList, currentPosition) {
    var productAttrObj = {
        quantity: quantity,
        position: currentPosition
    };

    dataLayer.push({ ecommerce: null });
    var obj = {
        event: 'e_addtocart',
        'preorder': '',
        'ga4_event_name':'add_to_cart',
        ecommerce: {
            add: {
                action_field: {
                    list: currentList
                }
            },
            currency: '',
            creative_name: '',
            creative_slot: '',
            promotion_id: '',
            promotion_name: '',
            item_list_id: '',
            item_list_name: '',
            items: []
        }
    };

    obj.ecommerce.items.push($.extend(productObject, productAttrObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}
// eslint-disable-next-line valid-jsdoc
/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 * @param {string} currentList
 * @param {string} currentPosition
 */
function viewCart(cartData) {

    dataLayer.push({ ecommerce: null });
    var obj = {
        event: 'view_cart',
        ga4_event_name:'view_cart',
        ecommerce: {
            currency: cartData.currency,
            value: cartData.value,
            item_list_id: '',
            item_list_name: '',
            creative_name: '',
            creative_slot: '',
            promotion_id: '',
            promotion_name: '',
            items: cartData.items
        }
    };
    dataLayer.push(obj);
}

/**
 *
 * @param {Object} productObject product object
 * @param {string|number} quantity product quantity
 */
function removeFromCart(productObject, quantity) {
    var prevRemoveStep;
    dataLayer.forEach(function name(ele) {
        if (ele.event === 'removeFromCart') {
            prevRemoveStep = ele;
            dataLayer.splice(dataLayer.indexOf(prevRemoveStep),1);
        }
    });
    var cartData = $.parseJSON($('#cartdata').attr('data-gtmdata'));
    var quantityObj = {
        quantity: quantity
    };

    dataLayer.push({ ecommerce: null });
    var obj = {
        event: 'removeFromCart',
        'ga4_event_name':'remove_from_cart',

        ecommerce: {
            currency: cartData.currency,
            value: productObject.price,
            item_list_id: '',
            item_list_name: '',
            creative_name: '',
            creative_slot: '',
            promotion_id: '',
            promotion_name: '',
            items: []
          }
    };

    obj.ecommerce.items.push($.extend(productObject, quantityObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}

/**
 * Updates the current step in the checkout flow
 * @param {Integer} step the step number the flow is currently on
 */
function updateCheckoutStep(step) {
    var checkoutStep;
    dataLayer.forEach(function name(ele) {
        if (ele.event === 'checkout') {
            checkoutStep = ele;
        }
    });

    if (step === 4) {
        dataLayer.splice(dataLayer.indexOf(checkoutStep),1);
        checkoutStep.ga4_event_name = 'add_payment_info';
        dataLayer.push(checkoutStep);
    } else if (step === 'shipping') {
        dataLayer.splice(dataLayer.indexOf(checkoutStep),1);
        checkoutStep.ga4_event_name = 'add_shipping_info';
        dataLayer.push(checkoutStep);
    }
}

/**
 * @description Convenience method for creating a click event.
 * @param {string} event event
 * @param {string} eventCategory event categroy
 * @param {string} eventAction event action
 * @param {string} eventLabel event layer
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

var previous;

/**
 * @description Convenience method for getting quantity went removing
 * @param {Object} productObj productObj
 * @return {int} quantity value of quantity
 */
function getQuantityValue(productObj) {
    var quantity = 1;
    $('select.quantity').each(function () {
        var datapid = $(this).attr('data-pid');
        if (datapid === productObj.id || datapid === productObj.childID) {
            quantity = $(this).val();
        }
    });
    return quantity;
}
var events = {
    account: function () {},
    cart: function () {
        $('.cart-page .number-input .quantity-btn').on('click', function () {
            var numberInput = $(this).parent().find('input');
            var newQty = parseInt(numberInput.val());
            var quantityCustomSelectGtmData = $.parseJSON($(this).parent().prev().attr('data-gtmdata'));
            if($(this).hasClass('btn-plus')) {
                var previous = newQty - 1;
                addToCart(quantityCustomSelectGtmData, newQty - previous);
            }
            if($(this).hasClass('btn-minus')) {
                var previous = newQty + 1;
                removeFromCart(quantityCustomSelectGtmData, previous - newQty);
            }
        });

        $('.cart-page .remove-product').on('click', function () {
            var productObj = $.parseJSON($(this).attr('data-gtmdata'));
            // Send removeFromCart event when confirmation is complete
            $(document).on('click', '.cart-delete-confirmation-btn', function () {
                removeFromCart(productObj, getQuantityValue(productObj));
            });
            $('.cart-page .remove-btn.remove-product').on('click', function () {
                removeFromCart($.parseJSON(productObj), getQuantityValue(productObj));
            });
        });

        $('.cart-page .add-to-cart').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), 1);
        });

        $('.add-tile-to-cart').on('click', function () {
            var currentPosition = $(this).closest('.product').attr('data-gtm-product-position');
            var currentList = $(this).parents('.page-recommendation').siblings('.callout-msg-recommendations').text().trim();
            addTileToCart($.parseJSON($(this).attr('data-gtmdata')), 1, currentList, currentPosition);
        });

        viewCart($.parseJSON($('#cartdata').attr('data-gtmdata')));
    },
    checkout: function () {

        $(document).on('click', '.btn-edit-multi-ship, .shipping-summary .edit-button', function () {
            updateCheckoutStep(2);
        });

        $(document).on('checkout:updateUrl', function (ev, currentStage) {
            if (currentStage === 'payment') {
                updateCheckoutStep(4);
            } else if (currentStage === 'placeOrder') {
                updateCheckoutStep(5);
            } else if (currentStage === 'shipping') {
                updateCheckoutStep('shipping');
            }
        });

        $(document).on('click', '.place-order', function () {
            updateCheckoutStep(6);
        });
    },
    compare: function () {},
    product: function () {
        $('.add-to-cart').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), $('.quantity-select').val());
        });
    },
    search: function () {
        $('.add-tile-to-cart').on('click', function () {
            var currentPosition = $(this).closest('.grid-tile').attr('data-gtm-product-position');
            var currentList = $(this).parents('.row[class$="-carousel"]').find('.callout-msg-feature-product').text().trim();
            addTileToCart($.parseJSON($(this).attr('data-gtmdata')), 1, currentList, currentPosition);
        });
        $(document).on('click', '.add-to-cart-global', function () {
        	addToCart($.parseJSON($(this).attr('data-gtmdata')), 1);
        });
    },
    storefront: function () {},
    wishlist: function () {},
    // events that should happen on every page
    all: function () {
        $('.pdp-link .link').on('click', function () {
            productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.nav-link.dropdown-toggle').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.navbar-header.brand').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
    }
};


/**
 *
 * @description Initialize the tag manager functionality
 * @param {string} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    var ns = nameSpace || pageContext.ns;
    if (ns && events[ns]) {
        events[ns]();
    }
    events.all();
};
