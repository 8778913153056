'use strict';

var baseClientSideValidation = require('base/components/clientSideValidation');

module.exports = $.extend(baseClientSideValidation, {
    /**
     * Clears the validation errors of an invalidated form element after a change occurs
     */
    clearErrorsOnChange: function () {
        $(':input, .form-control').on('change input', function () {
            if ($(this).hasClass('is-invalid')) {
                $(this).removeClass('is-invalid');
                $(this).closest('.invalid-feedback').remove();
            }
        });
    },

    invalid: function () {
        $('form:not(".no-validate") input, form:not(".no-validate") select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },

    submit: function () {
        $('form:not(".no-validate")').on('submit', function (e) {
            return baseClientSideValidation.functions.validateForm.call(this, e);
        });
    }
});
